
.map-results {
    background-color: #fff;
}

.map-result--address {
    width: 80%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.map-result--summary li {
    list-style-type:disc;
    list-style-position:inside;
}

.shop-desc {
    font-size:13px;
}

.map-pin {
    font-size:18px!important;
    color: #000;
    font-weight:300;
    display:inline-block;
    width:20px;
    text-align:center;
    z-index:1;
}

    .direction-actions button.active {
        background: #31953e;
    }

.no-results {
    text-align:center;
}

#result-filter {
    border-bottom: 1px lightgrey solid;
    padding-bottom: 26px;
    padding-left: 4px;
    position: relative;
    width: 400px;
    top: 80px;
}
@media only screen and (max-width: 800px) {
    #result-filter {
        width: 100%;
        padding-top: 20px;
        top: 0px;
    }
}

#result-filter ul {
    width: 100%;
    display: block;
}
@media only screen and (max-width: 800px) {
    #result-filter ul {
        width: 100%;
    }
}

    #result-filter ul li {
        display: inline-block;
        font-size: 13px;
        background-color: #f2f2f7;
        border: none;
        color: #73737D;
        margin-right: 10px;
        padding: 0px 3px;
        height: 36px;
        line-height: 36px;
        border-radius: 2px;
        cursor: pointer;
    }

@media only screen and (max-width: 330px) {
    #result-filter ul li {
        padding: 0px 1px;
        height: 30px;
        line-height: 30px;
        margin-right: 2px;
    }
}

        #result-filter ul li:last-child {
            margin-right: 0px;
        }

        #result-filter ul li span {
            color: inherit;
            padding: 0px 8px;
            display: block;
        }

        #result-filter ul li.active-filter {
            color: #000;
            background-color: #fff;
            border: 1px solid #D9DED0;
        }

#onetrust-pc-sdk .ot-pc-logo img {
    display: none;
}