@import url(//fonts.googleapis.com/css?family=RobotoDraft:300,400,700,900);
@import url(//fonts.googleapis.com/css?family=Inconsolata:700);
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  min-width: 100%;
  min-height: 100%;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html,
button,
input,
select,
textarea {
  font-family: "RobotoDraft", sans-serif; }

body {
  margin: 0; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

p,
a,
ul,
ol,
li,
div,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
button,
span,
strong,
em {
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important; }

h1 {
  font-size: 3em;
  margin: 0.67em 0; }

h2 {
  font-size: 2.6em;
  margin: 0.83em 0; }

h3 {
  font-size: 2em;
  margin: 1em 0; }

h4 {
  font-size: 1.8em;
  margin: 1.33em 0; }

h5 {
  font-size: 1.4em;
  margin: 1.67em 0; }

h6 {
  font-size: 1.2em;
  margin: 2.33em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

p,
pre {
  margin: 1em 0; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: "courier new", monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before,
q:after {
  content: "";
  content: none; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dl,
menu,
ol,
ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

menu,
ol,
ul {
  padding: 0 0 0 40px; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
input,
select,
textarea {
  color: #222; }

body {
  font-size: 1em;
  line-height: 1.4; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

body {
  font: 16px/26px Helvetica, Helvetica Neue, Arial; }

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px; }

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%; }

.hidden {
  display: none !important;
  visibility: hidden; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after,
.clear {
  clear: both; }

.clearfix {
  *zoom: 1; }

h1, h2, h3, h4, h5, h6, label, p, p strong, p em, strong, a, em, span, li, div, ul {
  font-family: "RobotoDraft";
  color: #202020;
  margin: 0;
  font-weight: normal !important;
  font-style: normal !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important; }

h1, h2, h3, h4, h5 {
  line-height: 1;
  font-weight: 500 !important; }

hr {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAECAYAAACtBE5DAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OENDRjNBN0E2NTZBMTFFMEI3QjRBODM4NzJDMjlGNDgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OENDRjNBN0I2NTZBMTFFMEI3QjRBODM4NzJDMjlGNDgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4Q0NGM0E3ODY1NkExMUUwQjdCNEE4Mzg3MkMyOUY0OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4Q0NGM0E3OTY1NkExMUUwQjdCNEE4Mzg3MkMyOUY0OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqqezsUAAAAfSURBVHjaYmRABcYwBiM2QSA4y4hNEKYDQxAEAAIMAHNGAzhkPOlYAAAAAElFTkSuQmCC) repeat-x 0 0;
  border: 0 none;
  height: 4px;
  padding: 0;
  margin: 2.5em 0;
  opacity: 0.5; }

input, textarea {
  font-family: "RobotoDraft";
  color: #202020;
  font-weight: normal !important;
  font-style: normal !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important; }

p {
  margin-bottom: 1em;
  line-height: 1.6; }

p strong {
  font-weight: 500 !important; }

p em {
  font-style: italic !important; }

p:last-child {
  margin-bottom: 0; }

a {
  color: #d5df3d;
  text-decoration: none; }

a:active,
a:focus,
.button,
.hollow-button,
.disabled-button {
  outline: none !important; }

.button,
.hollow-button,
.disabled-button {
  display: inline-block;
  line-height: 1.3;
  padding: 10px 0;
  background: #369442;
  border: 1px solid #286f31;
  color: #fff;
  font-size: 13px;
  -webkit-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3);
  text-align: center;
  width: 125px;
  font-weight: 500 !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #68c874;
  background-image: -moz-linear-gradient(#68c874, #369442);
  background-image: -o-linear-gradient(#68c874, #369442);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #68c874), color-stop(1, #369442));
  background-image: -webkit-linear-gradient(#68c874, #369442);
  background-image: -ms-linear-gradient(#68c874, #369442);
  background-image: linear-gradient(#68c874, #369442); }
  .button i:before,
  .hollow-button i:before,
  .disabled-button i:before {
    font-size: 0.9em; }

.button--account-action {
  background: #0073d5;
  border: 1px solid #0057a2;
  background-color: #3ca5ff;
  background-image: -moz-linear-gradient(#3ca5ff, #0073d5);
  background-image: -o-linear-gradient(#3ca5ff, #0073d5);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #3ca5ff), color-stop(1, #0073d5));
  background-image: -webkit-linear-gradient(#3ca5ff, #0073d5);
  background-image: -ms-linear-gradient(#3ca5ff, #0073d5);
  background-image: linear-gradient(#3ca5ff, #0073d5); }

.hollow-button {
    background: none;
    color: #009e51;
    border: 1px solid #d9eae0;
    box-shadow: none;
}

.disabled-button {
  background: #cccccc;
  border: 2px solid #cccccc; }
  .disabled-button:hover {
    cursor: default;
    background: #cccccc;
    border: 2px solid #cccccc; }

i:before {
  font-weight: normal !important;
  font-style: normal !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  font-weight: 400 !important; }

input,
textarea {
  border: 1px solid rgba(0, 72, 51, 0.25);
  background: #fff;
  padding: 10px;
  font-size: 15px;
  display: inline-block;
  line-height: 1.3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 400 !important;
  -webkit-appearance: none; }

input:focus,
textarea:focus {
  outline: none !important;
  background: #fff; }

::-webkit-input-placeholder {
  color: #000;
  opacity: 0.3; }

:-moz-placeholder {
  opacity: 0.4; }

::-moz-placeholder {
  opacity: 0.4; }

code {
  display: block;
  margin: 1.2em 0;
  background: #202020;
  color: #4AB471;
  padding: 6px 12px;
  font-size: 16px;
  font-family: "Inconsolata", mono;
  font-weight: normal !important;
  font-style: normal !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  font-weight: 500 !important; }

html,
body {
  width: 100%;
  height: 100%; }

html.reveal-menu,
body.reveal-menu {
  overflow-x: hidden; }

ul,
li {
  list-style-type: none;
  padding: 0; }

.padding {
  padding: 20px; }

.material-box {
  background: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0px 0px transparent;
  -moz-box-shadow: 0 0px 0px transparent;
  -o-box-shadow: 0 0px 0px transparent;
  box-shadow: 0 0px 0px transparent; }
  @media only screen and (max-width: 800px) {
    .material-box {
      -webkit-box-shadow: 0 1px 3px #d3d7dc;
      -moz-box-shadow: 0 1px 3px #d3d7dc;
      -o-box-shadow: 0 1px 3px #d3d7dc;
      box-shadow: 0 1px 3px #d3d7dc; } }

.content {
  width: 100%;
  height: calc(100% - 87px);
  margin: 0 auto;
  position: fixed;
  top: 87px;
  left: 0; }
  @media only screen and (max-width: 800px) {
    .content {
      height: auto;
      position: absolute; } }
  @media only screen and (max-width: 480px) {
    .content {
        top: 50px;
    }
  }

.map-bg {
    position:absolute;
    left: 400px;
    width: calc(100% - 400px);
    height: 100%;
    /*top: 70px;*/
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
  @media only screen and (max-width: 800px) {
    .map-bg {
        position: relative;

        width: 100%;
        /*height: 400px;*/
        padding-bottom: 100%;
        top: 0;
        left: 0;

    } }
  .map-bg #map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.direction-actions {
  display:none;
  width: 100%;
  padding-left: 620px;
  position: absolute;
  top: 20px;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box; }
  @media only screen and (max-width: 800px) {
    .direction-actions {
      position: relative;
      padding: 0;
      top: 0; } }
.direction-actions button {
    display: inline-block;
    width: auto;
    height: auto;
    margin-right: 10px;
    background: #d9dde0;
    border: 1px solid #bcc3ca;
    position: relative;
    color: #697784;
    font-size: 12px;
    line-height: 1.3;
    padding: 11px 16px;
    text-align: center;
    font-weight: 500 !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}
    .direction-actions button.active {
        background: #84909c;
        color: #fff;
        border: 1px solid #84909c;
    }
    @media only screen and (max-width: 800px) {
        .direction-actions button {
            margin: 0;
            font-size: 13px;
            padding: 11px 0px;
            float: left;
            width: 33.333%;
            border-right: none;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
        }
            .direction-actions button:first-of-type {
                border-left: none;
            } }
  .direction-actions i {
    margin-right: 5px; }
    @media only screen and (max-width: 800px) {
      .direction-actions i {
        display: none; } }

.map-results {
    position: relative;
    /*  top: 0;
  left: 0;*/
    width: 400px;
    height: 100%;
    overflow: hidden;    
    margin-top: 80px;
    /*padding-top: 70px;*/
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
  @media only screen and (max-width: 800px) {
    .map-results {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
        margin-top:0px;
    } }
  .map-results:after {
    content: "";
    width: 100%;
    height: 80px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba(245, 246, 247, 0);
    background-image: -moz-linear-gradient(rgba(245, 246, 247, 0), #f5f6f7);
    background-image: -o-linear-gradient(rgba(245, 246, 247, 0), #f5f6f7);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(245, 246, 247, 0)), color-stop(1, #f5f6f7));
    background-image: -webkit-linear-gradient(rgba(245, 246, 247, 0), #f5f6f7);
    background-image: -ms-linear-gradient(rgba(245, 246, 247, 0), #f5f6f7);
    background-image: linear-gradient(rgba(245, 246, 247, 0), #f5f6f7); }

.map-results--search-bar {
  padding: 20px 4px;
  overflow: hidden;
/*  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;*/
  z-index: 999;
/*  background: #f5f6f7;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;*/
  box-sizing: border-box;
  position:absolute;
  top:0px;
  left:0px;
  width:400px;
}
  @media only screen and (max-width: 800px) {
    .map-results--search-bar {
        /*height: 80px;*/
        position: absolute;
        /*float: left;*/
        width: 100%;
        background: none;
        margin-top: 20px;
        padding: 0 4px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
    } }
.map-results--search-bar input {
    width: 340px;
    /*height: 40px;*/
    float: left;
    /*font-size: 14px;*/
    padding-left: 30px;
    background-image: url("../img/search.png");
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: 6px;
}
    @media only screen and (max-width: 800px) {
        .map-results--search-bar input {
            width: calc(100% - 50px);
            margin-bottom: 10px;
            float:none;
        } 
    }
.map-results--search-bar button {
    display: inline-block;
    /*float: right;
    width: auto;*/
    height: 42px;
    /*margin-left: 10px;
    background: #d9dde0;*/
    border-top: 1px solid #bcc3ca;
    border-right: 1px solid #bcc3ca;
    border-bottom: 1px solid #bcc3ca;
    border-left: 1px solid #bcc3ca;
    position: relative;
    color: #31953e;
    font-size: 25px;
    line-height: 26px;
    padding: 6px 10px;
    /*font-weight: 500 !important;*/
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    /*   -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;*/
    right: 1px;
}
    @media only screen and (max-width: 800px) {
        .map-results--search-bar button {
            width: 40px;
            margin: 0;
            text-align: center;
            /*position:absolute;*/
            /*right:40px;*/
            background-color: #fff;
            border: 1px solid #bcc3ca;
            right: 5px;
            top: 3px;
        }
       /* .map-results--search-bar a:first-of-type {
          display: none; }*/

    }
  .map-results--search-bar i {
    /*margin-right: 5px;*/
  }

.map-results--inner {
    /*width: 400px;*/
    height: 100%;
    overflow-y: scroll;
    position: relative;
    scrollbar-width: thin;
    padding-left: 16px;
    padding-right: 16px;
}
    .map-results--inner::-webkit-scrollbar {
        width: 6px;
    }
    .map-results--inner::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
    .map-results--inner::-webkit-scrollbar-track-piece {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    }
    .map-results--inner::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }

  @media only screen and (max-width: 800px) {
    .map-results--inner {
      /*width: 100%;*/ 
    } 
  }

.map-result--number {
  position: absolute;
  top: 0;
  left: 0; }
  .map-result--number:before {
    content: "";
    width: 100px;
    height: 100px;
    background: #84909c;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    top: -60px;
    left: -60px; }
  .map-result--number span {
    position: relative;
    top: 2px;
    left: 10px;
    color: #fff;
    font-weight: 500 !important; }

.map-result {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-bottom: 1px solid #f2f2f7;
    padding-top: 26px;
    padding-bottom: 26px;
    -webkit-tap-highlight-color: transparent;
}
  .map-result.open .map-result--number:before {
    background: #31953e; }
  .map-result.open .map-result--primary-img {
    padding-bottom: 60%; }
    @media only screen and (max-width: 800px) {
      .map-result.open .map-result--primary-img {
        padding-bottom: 30%; } }
  .map-result.open .collapsable {
    display: block;
    margin-top:10px;
  }
  .map-result:last-child {
    margin-bottom: 80px; }

.map-result-icon {
    width: 50px;
    display: inline-block;
    text-align: center;
    vertical-align: top;    
}

.collapsable {
  display: none; 
}

.map-result--primary-img {
  width: 100%;
  height: 0;
  padding-bottom: 50px;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -ms-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

.map-result--content {
    position: relative;
    width:305px;
    display:inline-block;
}

@media only screen and (max-width: 800px) {
    .map-result--content {
        width: calc(100% - 60px);
    }
  }

    .map-result--title {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .map-result-openinfo {
        margin-top: 20px;
       /* width: 200px;*/
        display: inline-block;
    }
    @media only screen and (max-width: 380px) {
        .map-result-openinfo {
            width: 95px;
        }
        .map-result-openinmaps {
            margin-top: 20px;
        }
        .map-result-openinmaps a {
            width: 115px;
        }
    }


    .map-result-openinfo .open-indicator,
    .map-result-openinfo .closed-indicator {
        /*    float: right;
    display: block;
    font-size: 10px;
    background: #31953e;*/
        color: #009e51;
        /*padding: 5px;*/
        position: relative;
        /*top: -2px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;*/
        /*font-weight: 500 !important;*/
    }

    .map-result-openinmaps {
        /*       display: inline-block;
        width: 130px;
        position: absolute;*/
        float: right;
    }

    .map-result-openinfo .closed-indicator {
        /*background: #bcc3ca;*/
        color: #BB3838;
    }

    .map-result-extraaddress {
        width: 200px;
        line-height: 1.3;
    }
        @media only screen and (max-width: 360px) {
            .map-result-extraaddress {
                width: 150px;
            }
        }

    .map-result-openinfo-storestatus {
        display: inline-block !important;
        width: 60px;
    }

    .map-result-openinfo-openstatus {
        display: inline-block !important;
        color: #85858d;
    }

    .near-indicator {
        position: absolute;
        top: 32px;
        right: 2px;
        text-align: center;
        font-size: 11px;
        line-height: 1.3;
        color: #84909c;
        font-weight: 500 !important;
    }

        .near-indicator i {
            font-size: 14px;
        }

    .map-result--title-meta {
        line-height: 1;
        display: block;
        font-size: 13px;
    }

        .map-result--title-meta span {
            display: block;
            margin-bottom: 5px;
            font-weight: 500 !important;
        }

        .map-result--title-meta .faded {
            opacity: 0.5;
        }

    .map-result--opening-hours {
        border-top: 1px solid #bcc3ca;
        border-bottom: 1px solid #bcc3ca;
        padding: 10px 0;
        margin: 20px 0 15px 0;
    }

        .map-result--opening-hours li {
            font-size: 14px;
            line-height: 1.4;
            font-weight: 500 !important;
        }

        .map-result--opening-hours span {
            float: right;
            font-weight: 500 !important;
        }

    .map-result--summary {
        font-size: 13px;
        line-height: 1.4;
    }

        .map-result--summary .shop-name {
            display: block;
            font-size: 11px;
            margin-top: 10px;
            color: #697784;
        }

    .map-result--actions {
        overflow: hidden;
        margin-top: 15px;
    }

        .map-result--actions a {
            float: left;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
        }

            .map-result--actions a:nth-of-type(2) {
                float: right;
                margin: 0;
            }

        .map-result--actions i {
            margin-right: 5px;
        }

.directions-button {
    display: none;
    width: 100%;
}

#no-results {
    display: none;
}

.is-visible {
    display: block;
}

.is-hidden {
    display: none;
}


/* Header styles */

.header-mobile {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    display: none;
}

@media only screen and (max-width: 480px) {
    .header-desktop {
        display: none;
    }

    .header-mobile {
        display: flex;
    }
}

.header-primary {
    font-family: "RobotoDraft", sans-serif;
    background-color: #ffffff;
    position: relative;
    z-index: 1;
}

.topbar {
    padding: 0 62px;
    background-color: #004833;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topbar__link {
    cursor: pointer;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700 !important;
    padding: 6px;
}

.topbar-nav {
    display: flex;
    align-items: center;
}

.topbar-nav-right {
    display: flex;
    align-items: right;
}

.topbar-nav__item {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400 !important;
    line-height: 150%;
    color: #ffffff;
    padding: 6px;
}

.topbar-nav__item--active {
    background-color: #ffffff;
    color: #004833;
}

.secondary-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 62px 12px;
}

.secondary-bar__link {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700 !important;
    color: #004833;
    padding: 0 15px;
}

.authentication-container--sm {
    display: flex;
    align-items: center;
}

.authentication-container--sm .btn-link {
  font-weight: 700 !important;
}

/* Button Stylings */

.btn {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700 !important;
    color: #ffffff;
    line-height: 150%;
    padding: 3px 8px;
    border-radius: 4px;
    border: 2px solid transparent;
}

.btn--primary {
    background-color: #009745;
}

.btn-link {
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #006858;
}

.btn--primary.btn--ghost {
    background-color: transparent;
    border-color: #009745;
    color: #009745;
}

.link-separator {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0 8px;
    background-color: #009745;
}
